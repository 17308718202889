<template>
  <div class="profile">
    <div class="sub-header">
      <div class="sub-header__wrap container flex">
        <h1 class="sub-header__title main-title">Мой аккаунт</h1>
        <button class="sub-header__btn main-btn" @click="editProfile()">Сохранить</button>
      </div>
    </div>
    <div v-if="profileProducts" class="profile__block container flex">
      <!--      <div class="profile__left flex" :style="`background-image: url(${$url + profileProducts.logo});`">-->
      <!--        <button class="profile__left-btn">-->
      <!--          <b-icon class="profile__left-icon" icon="camera" font-scale="1.1"></b-icon>-->
      <!--          Загрузить фото-->
      <!--        </button>-->
      <!--      </div>-->
      <div class="profile__right">
        <div class="profile__data">
          <h2 class="profile__data-title">Данные аккаунта</h2>
          <div class="profile__data-row flex">
            <div class="profile__data-col">
              <div class="profile__data-item">
                <label for="">Имя</label>
                <b-form-input
                  v-model="name"
                  type="text"
                  class="profile__input main-input"
                  placeholder="Введите свой имя"
                  required
                />
              </div>
              <div class="profile__data-item">
                <label for="">Страна</label>
                <b-form-select
                  v-model="region"
                  class="main__select main-select"
                  :options="countries"
                  text-field="name"
                  value-field="id"
                />
                <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
              </div>
              <div class="profile__data-item">
                <label for="">Название компании</label>
                <b-input
                  v-model="nameCompany"
                  type="text"
                  class="profile__input main-input"
                  placeholder="Введите название компании"
                  required
                />
              </div>
              <div class="profile__data-item">
                <label for="">Email</label>
                <b-input
                  v-model="email"
                  type="email"
                  class="profile__input main-input item-end"
                  placeholder="Введите свой email"
                  required
                />
              </div>
            </div>
            <div class="profile__data-col">
              <div class="profile__data-item">
                <label for="">Фамилия</label>
                <b-input
                  v-model="surname"
                  type="text"
                  class="profile__input main-input"
                  placeholder="Введите свой фамилия"
                  required
                />
              </div>
              <div class="profile__data-item">
                <label for="">Город</label>
                <b-form-select
                  v-model="city"
                  class="main-select main__select"
                  :options="cities"
                  text-field="name"
                  value-field="id"
                  required
                />
                <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
              </div>
              <div class="profile__data-item">
                <label for="">Должность</label>
                <b-form-select
                  v-model="position"
                  class="profile__input main-input"
                  :options="positionsProducts.data.data"
                  text-field="title"
                  value-field="id"
                />
              </div>
              <div class="profile__data-item">
                <label for="">Номер телефона</label>
                <vue-tel-input
                  v-model="phone"
                  type="number"
                  class="profile__input main-input input_tel item-end"
                  placeholder="Введите номер телефона"
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div class="profile__data">
          <h2 class="profile__data-title">Пароль</h2>
          <div class="profile__data-row flex">
            <div class="profile__data-col">
              <div class="profile__data-item">
                <label for="">Текущий пароль</label>
                <b-input
                  v-model="password"
                  type="text"
                  class="profile__input main-input"
                  placeholder=""
                  required
                />
              </div>
              <div class="profile__data-item">
                <label for="">Новый пароль</label>
                <b-input
                  v-model="newPassword"
                  type="text"
                  class="profile__input main-input"
                  placeholder=""
                  required
                />
              </div>
              <div class="profile__data-item">
                <label for="">Повторите пароль</label>
                <b-input
                  v-model="newPasswordRepeat"
                  type="text"
                  class="profile__input main-input item-end"
                  placeholder=""
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      name: "",
      surname: "",
      nameCompany: "",
      position: "",
      phone: "",
      email: "",
      password: null,
      city: null,
      region: null,
      newPassword: "",
      newPasswordRepeat: "",
    };
  },
  computed: {
    ...mapState(["profileProducts", "citiesProducts", "countriesList", "positionsProducts"]),
    cities() {
      if (this.citiesProducts) {
        return [
          {
            id: null,
            name: "Введите свой город",
            disabled: true,
          },
          ...this.citiesProducts,
        ];
      }
      return [
        {
          id: null,
          name: "Введите свой город",
          disabled: true,
        },
      ];
    },
    countries() {
      if (this.countriesList) {
        return [
          {
            id: null,
            name: "Введите свой страна",
            disabled: true,
          },
          ...this.countriesList.data,
        ];
      }
      return [
        {
          id: null,
          name: "Введите свой страна",
          disabled: true,
        },
      ];
    },
  },
  watch: {
    profileProducts() {
      if (this.profileProducts) {
        this.name = this.profileProducts.name;
        this.email = this.profileProducts.login;
        // this.surname = this.profileProducts.surname
        // this.nameCompany = this.profileProducts.role
        this.position = this.profileProducts.position.id;
        // this.phone = this.profileProducts.phone
        this.city = this.profileProducts.city;
        // this.region = this.profileProducts.city.region_id
      }
    },
  },
  async created() {
    await this.$store.dispatch("getProfileProducts");
    await this.$store.dispatch("getCitiesProducts");
    await this.$store.dispatch("getCountriesList");
    await this.$store.dispatch("getPositionsProducts");
  },
  mounted() {
    if (this.profileProducts && this.citiesProducts && this.countriesList) {
      this.name = this.profileProducts.name;
      this.email = this.profileProducts.login;
      // this.surname = this.profileProducts.surname
      this.position = this.profileProducts.position.id;
      // this.nameCompany = this.profileProducts.role
      // this.phone = this.profileProducts.phone
      this.city = this.profileProducts.city;
      // this.region = this.profileProducts.city.region_id
    }
  },
  methods: {
    async editProfile() {
      if (this.newPassword !== this.newPasswordRepeat) {
        this.$toast.warning("не совпадает");
        return;
      }
      const formData = new FormData();
      if (this.name) formData.append("name", this.name);
      // if (this.address) formData.append('address', this.address)
      // if (this.city) formData.append('city_id', this.city)
      if (this.email) formData.append("login", this.email);
      if (this.position) formData.append("position_id", this.position);
      if (this.password) formData.append("old_password", this.password);
      if (this.newPassword) formData.append("new_password", this.newPassword);
      // if (this.email) formData.append('password', this.password)
      await this.$api.post("/web/edit-profile", formData).then(() => {
        this.password = null;
        this.newPassword = null;
        this.newPasswordRepeat = null;
        this.$toast.success("Успешно");
        this.$store.dispatch("getCitiesProducts");
        this.$store.dispatch("getProfileProducts");
      });
    },
  },
};
</script>

<style scoped>
.profile__block {
  padding: 20px 0 0 0;
  align-items: flex-start;
  justify-content: center;
}

.profile__left {
  width: 200px;
  height: 200px;
  border-radius: 20px;
  background: #e0e9fa;
  align-items: flex-end;
  /* background-image: url(../../assets/png/profile-foto.png); */
  background-repeat: repeat;
  background-position: center center;
}

.profile__left-btn {
  width: 100%;
  height: 40px;
  background: rgba(50, 50, 50, 0.4);
  backdrop-filter: blur(5px);
  color: #ffffff;
  border-radius: 0 0 20px 20px;
}

.profile__left-icon {
  margin-right: 5px;
}

.profile__right {
  width: 70%;
  margin: 0 90px 0 110px;
}

.profile__data {
  padding: 20px;
  border-radius: 20px;
  background: #ffffff;
  margin-bottom: 20px;
}

.profile__data-row {
  margin-bottom: 5px;
}

.profile__data-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
}

.profile__data-col {
  width: 48%;
}

.profile__data-item {
  width: 100%;
  position: relative;
}

.profile__data-btn {
  width: 80%;
  height: 48px;
  margin-top: 12px;
  text-align: start;
}

.profile__input,
.main__select {
  margin: 5px 0 15px 0;
}

.item-end {
  margin-bottom: 0;
}

.sub-header {
  padding: 18px 0;
  background-color: #fff;
  border-top: 1px solid #f2f2f8;
}

.sub-header__btn {
  width: auto;
  height: 40px;
  padding: 10px;
  border-radius: 8px;
}
</style>
